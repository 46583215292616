import { useEffect, useState } from 'react'
import ListaVentas from '../../components/PuntoVenta/lista-ventas'
import { PuntoVentaProvider } from '../../components/PuntoVenta/punto-venta-box'
import VentaProceso from '../../components/PuntoVenta/caja-venta/venta-proceso'
import ListaProductos from '../../components/PuntoVenta/productos/lista-productos'
import { use } from 'react'

const PuntoDeVenta = ({ ShowAlert }) => {
    const [ventas, setVentas] = useState([])
    const [productos, setProductos] = useState([])
    const [Conceptos, setConceptos] = useState([]);

    const AgregarConceptoDetalle = () => {
        setConceptos([...Conceptos, {
            nombre: "Producto",
            precio: 100,
            cantidad: 2,
        }]);
    }

    useEffect(() => {
        let ventas = []
        for (let i = 0; i < 20; i++) {
            ventas.push({
                id: i,
                titulo: 'Venta',
                subtitulo: '#4590',
                productos: 6,
                estatus: 'En proceso'
            })
        }
        setVentas(ventas)
    }, [])

    useEffect(() => {
        let productos = []
        for (let i = 0; i < 20; i++) {
            productos.push({
                id: i,
                nombre: 'Producto',
                categoria: 'Analgesico',
                codigo: '123456',
                cantidad: 10,
                precio: "100.00"
            })
        }
        setProductos(productos)
    }, [])

    return <>
        <PuntoVentaProvider
            ShowAlert={ShowAlert}
        >
            <ListaVentas
                ventas={ventas}
            />
            <ListaProductos
                productos={productos}
                addConcepto={AgregarConceptoDetalle}
            />
            <VentaProceso
                conceptos={Conceptos}
                deleteConcepto={(index) => {
                    let conceptos = [...Conceptos];
                    conceptos.splice(index, 1);
                    setConceptos(conceptos);
                }}
            />
        </PuntoVentaProvider>
    </>
}

export default PuntoDeVenta