import './CardInformacion.css'
import { ImageInput, ButtonText } from 'eureka-design'

const CardInformacion = ({
    foto = '',
    titulo = 'Sin Titulo', 
    informacion = [],
    permitirEditar = true, 
    handlerEditar = () => {} 
}) => {
    return (
        <div className="card-informacion">
            <ImageInput 
                preview={foto}
                width={250}
                height={250}
            />
            <div className="card-informacion__detalles">
                <div className="card-informacion__detalles__titulo">
                    <span>{titulo}</span>
                    { permitirEditar ? <ButtonText icono="fa-pen" texto="editar" onClick={handlerEditar}/> : null }
                </div>
                {
                    informacion.map((info, indice) => {
                        return (
                            <p 
                                className="card-informacion__detalles__extra"
                                key={indice}
                            >
                                {info.etiqueta}: <span>{info.valor}</span>
                            </p>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default CardInformacion