import "./venta-proceso.css";
import DesgloseDetalles from "../desglose-detalle/desglose-detalles";
import MovimientoTipo from "./movimiento-tipo";
import MetodoPago from "../metodo-pago/metodo-pago";
import VentaTicket from "../ticket-venta/venta-ticket";
import { useState } from "react";
import { ButtonIcon } from "eureka-design"

const pantalla = Object.freeze({
    Movimiento: "Movimiento",
    Desglose: "Desglose",
    Metodo: "Metodo",
    Terminado: "Terminado",
});

const VentaProceso = ({ conceptos, deleteConcepto = () => { } }) => {
    const [Pantalla, setPantalla] = useState(pantalla.Movimiento);

    const handlerPantallaMain = (screen) => {
        let result = pantalla[screen] ?? null
        if (result) {
            setPantalla(pantalla[screen])
        }
    }

    return (
        <div className="venta-proceso">
            <div className="venta-proceso__box">
                <div className="venta-proceso__card">
                    <div className="venta-proceso__header">
                        <div className="venta-proceso__header-back">
                            <ButtonIcon
                                icono={"fa-arrow-left"}
                            />
                        </div>
                        <div className="venta-proceso__header-titulo">
                            Pago
                        </div>
                    </div>
                    <div className="venta-proceso__content">
                        {Pantalla === pantalla.Movimiento ? <MovimientoTipo onClick={() => setPantalla(pantalla.Desglose)} /> : undefined}
                        {Pantalla === pantalla.Desglose ? <DesgloseDetalles
                            changeScene={handlerPantallaMain}
                            deleteConcepto={deleteConcepto}
                            productos={conceptos}
                        /> : undefined}
                        {Pantalla === pantalla.Metodo ? <MetodoPago
                            changeScene={handlerPantallaMain}
                        /> : undefined}
                        {Pantalla === pantalla.Terminado ? <VentaTicket onClick={() => setPantalla(pantalla.Desglose)} /> : undefined}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VentaProceso;