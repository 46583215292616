import "./ticket.css"

export default function VentaTicket() {
    return <>
        <div className="venta-ticket">
            <div className="venta-ticket__header">
                <div className="venta-ticket__estatus">
                    <div className="venta-ticket__estatus-icono">
                        <i className="fas fa-circle-check"></i>
                    </div>
                    <div className="venta-ticket__estatus-info">
                        <div className="venta-ticket__estatus-titulo">
                            Pago realizado con éxito
                        </div>
                        <div className="venta-ticket__estatus-text">
                            Tu venta se procesó exitosamente
                        </div>
                    </div>
                </div>
            </div>
            <div className="venta-ticket__body">
                <div className="ticket-recibo">
                    <div className="ticket-recibo__header">
                        <div className="ticket-recibo__logo">

                        </div>
                    </div>
                    <div className="ticket-recibo__info">
                        <div className="ticket-recibo__info-detalle">
                            <span>Folio:</span>
                            <font>4567</font>
                        </div>
                        <div className="ticket-recibo__info-detalle">
                            <span>Cliente:</span>
                            <font>Andrea Martínez López</font>
                        </div>
                        <div className="ticket-recibo__info-detalle">
                            <span>Fecha:</span>
                            <font>Miércoles 4 diciembre 2024</font>
                        </div>
                        <div className="ticket-recibo__info-detalle">
                            <span>Correo:</span>
                            <font>andrea@gmail.com</font>
                        </div>
                        <div className="ticket-recibo__info-detalle">
                            <span>Método de pago:</span>
                            <font>Tarjeta terminación **12</font>
                        </div>

                    </div>
                    <div className="ticket-recibo__conceptos">

                    </div>
                </div>
            </div>
            <div className="venta-ticket__footer">

            </div>
        </div>
    </>
}