import nouser from "../imgs/nouser.jpg"
import { config } from "../config/config"

export const MESES = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
]

export const ESTATUS_USUARIOS = [
    { id: 'inactivo', text: 'Suspendido' },
    { id: 'activo', text: 'Activo' },
    { id: 'bloqueado', text: 'Bloqueado' },
]

export function formatFecha(fecha) {
    // Asegurarnos de que la fecha sea un objeto Date
    if (!(fecha instanceof Date)) {
        return "Por favor, proporciona un objeto Date válido.";
    }

    // Configurar las opciones para el formato
    const opciones = {
        weekday: 'long', // Nombre del día (lunes, martes, etc.)
        year: 'numeric',
        month: 'long',  // Nombre completo del mes
        day: 'numeric'  // Día del mes
    };

    // Formatear la fecha en español
    return fecha.toLocaleDateString('es-ES', opciones);
}


/**
 * 
 * @param {string} cadena 
 * @returns 
 */
export const capitalize = (cadena = '', defecto = 'Sin rol') => {
    if (cadena && cadena.length > 0) {
        cadena = cadena.toLowerCase()
        let firstLetter = cadena.charAt(0)
        let firstLetterCap = firstLetter.toUpperCase()
        let remainingLetters = cadena.slice(1)
        return firstLetterCap + remainingLetters
    }
    else {
        return defecto
    }
}

/**
 * 
 * @param {float} number 
 * @param {int} decimals 
 * @param {string} decPoint 
 * @param {string} thousandsSep 
 * @param {string} dollarSimb 
 * @returns 
 */
export const numberFormat = (number = 0.0, decimals = 2, decPoint = '.', thousandsSep = ',', dollarSimb = '$') => {
    number = number ? number : 0
    number = Number(number).toFixed(decimals)
    let numberStr = String(number)
    let posDec = numberStr.indexOf('.')
    let numberIntStr = numberStr.slice(0, posDec)
    let decimalsStr = numberStr.slice(posDec).replace('.', decPoint)
    let digitsCount = 0
    let numberFormat = []


    for (let i = numberIntStr.length - 1; i >= 0; i--) {
        if (digitsCount === 3) {
            digitsCount = 0
            numberFormat.push(thousandsSep)
        }

        digitsCount++
        numberFormat.push(numberIntStr[i])
    }
    numberStr = numberFormat.reverse().join('') + decimalsStr

    if (dollarSimb) {
        return `${dollarSimb}${numberStr}`
    }
    else {
        return numberStr
    }
}

/**
 * @param {Date|null} fecha 
 */
export const adaptarFechaBD = (fecha = null, incluirTiempo = false) => {
    fecha = fecha ? fecha : new Date()
    return `${fecha.getFullYear()}-${fecha.getMonth() + 1}-${fecha.getDate()}${incluirTiempo ? `${fecha.getHours()}:${fecha.getMinutes()}:${fecha.getSeconds()}` : ''}`

}

/**
 * @param {string} urlOriginal 
 * @returns 
 */
export const obtenerURLObjeto = async (urlOriginal = null) => {
    if (urlOriginal === null) return null
    else {
        let archivo = await fetch(urlOriginal)
        archivo = await archivo.blob()
        return URL.createObjectURL(archivo)
    }
}

/**
 * @param {object} archivo 
 * @param {string} nombre 
 * @param {string} tipo 
 */
export const descargar = async (archivo = null, nombre = 'documento', tipo = 'descargar') => {
    let a = document.createElement('a')
    a.href = await obtenerURLObjeto(`${config.urlCRUFDEKArchivo}/${archivo?.carpeta}/${archivo?.nombre}`)

    if (tipo === 'abrir') a.target = '_blank'
    else a.download = nombre

    a.click()
}

export const validarTelefono = (val = '') => {
    return val
}

export const validarCorreoElectronico = (val = '') => {
    return val
}

/**
 * 
 * @param {Array} archivos  
 * @param {string} carpeta 
 * @param {*} imagenError
 * @returns 
 */
export const obtenerLogo = (archivos = [], carpeta = 'logo', imagenError = null) => {
    if (archivos instanceof Array) {
        if (archivos.length > 0) {
            let multimedias = archivos.filter((multimedia) => {
                return multimedia.carpeta === carpeta
            })
            let multimedia = multimedias[0]
            if (multimedia)
                return `${config.urlCRUFDEKArchivo}/${multimedia.carpeta}/${multimedia.nombre}`
            else
                return imagenError ? imagenError : nouser
        }
        else {
            return imagenError ? imagenError : nouser
        }
    }
    else if (archivos instanceof Object) {
        let multimedia = archivos
        return `${config.urlCRUFDEKArchivo}/${multimedia?.carpeta}/${multimedia?.nombre}`
    }
    else return imagenError ? imagenError : nouser
}

/**
 * @param {Array} archivos 
 * @param {string} carpeta 
 * @returns 
 */
export const obtenerArchivo = (archivos = [], carpeta = 'logo', obtenerTodos = false) => {
    if (archivos instanceof Array) {
        if (archivos.length > 0) {
            let multimedias = archivos.filter((multimedia) => {
                return multimedia.carpeta === carpeta
            })

            if (obtenerTodos) return multimedias
            else {
                let multimedia = multimedias[0]
                if (multimedia) return multimedia
                else return obtenerTodos ? [] : null
            }
        }
        else {
            return obtenerTodos ? [] : null
        }
    }
    else if (archivos instanceof Object) {
        let multimedia = archivos
        return multimedia
    }
    else return obtenerTodos ? [] : null
}