import "./movimiento-tipo.css";
import { useState } from "react";


const MovimientoTipo = ({ onClick = () => { } }) => {

    return (
        <div className="movimiento-tipo">
            <div className="movimiento-tipo__header">
                <div className="movimiento-tipo__header-titulo">
                    {/* <h2>Generar nueva</h2> */}
                </div>
                <div className="movimiento-tipo__header-buttons">

                </div>
            </div>
            <div className="movimiento-tipo__body">
                <div className="movimiento-tipo__lista">
                    <div className="movimiento-tipo__lista-item" onClick={onClick}>
                        <div className="movimiento-tipo__lista-item__icon">
                            <i className="fas fa-users"></i>
                        </div>
                        <div className="movimiento-tipo__lista-item__text">
                            <span>Venta a clientes</span>
                        </div>
                    </div>
                    <div className="movimiento-tipo__lista-item" onClick={onClick}>
                        <div className="movimiento-tipo__lista-item__icon">
                            <i className="fas fa-shop"></i>
                        </div>
                        <div className="movimiento-tipo__lista-item__text">
                            <span>Compra a proveedores</span>
                        </div>
                    </div>
                    <div className="movimiento-tipo__lista-item" onClick={onClick}>
                        <div className="movimiento-tipo__lista-item__icon">
                            <i className="fas fa-truck-ramp-box"></i>
                        </div>
                        <div className="movimiento-tipo__lista-item__text">
                            <span>Cargar inventario</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MovimientoTipo;