import CardProducto from '../../CardProducto/CardProducto'
import './ListaMedicamentos.css'

const ListaMedicamentos = ({
    medicamentoPrincipal = null,
    otrosMedicamentos = [],
    permitirEditar = false,
    onChange = () => {}
}) => {
    return (
        <div className="lista-medicamentos">
            <div className="lista-medicamentos__seccion">
                <p className="lista-medicamentos__seccion__titulo lista-medicamentos__seccion__titulo--invisible">NULL</p>
                <CardProducto producto={medicamentoPrincipal}/>
            </div>
            
            <div className="lista-medicamentos__separacion"></div>

            <div className="lista-medicamentos__seccion">
                <p className="lista-medicamentos__seccion__titulo">Otros:</p>
                <div className="lista-medicamentos__seccion__lista">
                    {
                        otrosMedicamentos.map((medicamento, indice) => {
                            return (
                                <CardProducto 
                                    key={indice} 
                                    producto={medicamento} 
                                    modoNormal={false} 
                                    permitirEditar={permitirEditar}
                                    onClick={() => {
                                        onChange(medicamento, indice)
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ListaMedicamentos