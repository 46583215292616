import { Input, Chip, ButtonText, Button } from "eureka-design"
import "./efectivo.css"
import { useState, useRef, useEffect } from "react";
import { numberFormat } from "../../../js/util";
import { usePuntoVenta } from "../../PuntoVenta/punto-venta-box"

export default function Efectivo({ total = 0, changeScreen = () => { } }) {
    const [Total] = useState(total);
    const [Cambio, setCambio] = useState(0);
    const [Cantidad, setCantidad] = useState(0);

    const inputRef = useRef(null);
    const padRef = useRef([]);
    const { ShowAlert } = usePuntoVenta()
    const TeclasPad = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'Delete'
    ];
    const ChipsMontos = [
        "Dinero exacto", "1000", "500", "200", "100", "50", "20"
    ];

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handlerAccessFast = (cantidad) => {
        if (cantidad !== "Dinero exacto") {
            handlerCambio(cantidad)
            setCantidad(cantidad)
        } else {
            handlerCambio(Total)
            setCantidad(Total)
        }
    }

    const handlerNumericPad = (cantidad) => {
        let parse_cantidad = Cantidad == 0 ? "" : String(Cantidad);
        parse_cantidad = parse_cantidad + cantidad
        handlerCambio(parse_cantidad)
        setCantidad(parse_cantidad)
    }

    const handlerCambio = (cantidad) => {
        let cambio = parseFloat(Total) - parseFloat(cantidad)
        if (parseFloat(Total) > parseFloat(cantidad)) { setCambio(Math.abs(0)) }
        else setCambio(Math.abs(cambio));
    }

    const handlerBackSpace = () => {
        let parse_cantidad = String(Cantidad);
        setCantidad(parse_cantidad.slice(0, -1));
        handlerCambio(parse_cantidad)

    }

    const handleKeyDown = (event) => {
        const { key } = event;
        if (!TeclasPad.includes(key) && key !== "Backspace") {
            event.preventDefault();
            return;
        }
        if (key === '.' && String(Cantidad).includes('.')) {
            event.preventDefault();
            return;
        }

        handleKeyDownAnimated(padRef.current[TeclasPad.findIndex((pad) => pad === key)])

        if (key === "Delete" || key === "Backspace") {
            handlerBackSpace()
        } else {
            let tecla_pad = TeclasPad.find((pad) => pad === key);
            handlerNumericPad(tecla_pad)
        }
    };
    const handleKeyDownAnimated = (pad) => {
        if (pad) {
            pad.classList.remove("panel-numerico__num-button--animate");
            void pad.offsetWidth;
            pad.classList.add("panel-numerico__num-button--animate");
            setTimeout(() => {
                pad.classList.remove("panel-numerico__num-button--animate");
            }, 500);
        }
    }
    return <>
        <div className="metodo-pago__efectivo" ref={inputRef}
            tabIndex={0}
            onKeyDown={handleKeyDown}>
            <div className="pago-efectivo__cantidad-box">
                <div className="pago-efectivo__captura">
                    <div className="pago-efectivo__cantidad-input">
                        <Input
                            placeholder="Cantidad entregada por el cliente"
                            type="precio"
                            iconLeft="fa-dollar-sign"
                            nombre="cantidad"
                            max={999999}
                            value={Cantidad}
                        />
                    </div>
                    <div className="pago-efectivo__cantidad-cambio">
                        <font className="pago-efectivo__cantidad-cambio__titulo">Cambio:</font>
                        <span className="pago-efectivo__cantidad-cambio__text">{numberFormat(Cambio)}</span>
                    </div>
                </div>
                <div className="pago-efectivo__montos">
                    {
                        ChipsMontos.map((item, index) => {
                            if (item == "Dinero exacto") {
                                return <Chip
                                    onClick={(upSelect) => {
                                        // upSelect(true)
                                        handlerAccessFast(item)
                                    }}
                                    key={index}
                                    texto={item}
                                />
                            }
                            return <Chip
                                onClick={(upSelect) => {
                                    // upSelect(true)
                                    handlerAccessFast(item)
                                }}
                                key={index}
                                texto={"$" + item}
                            />
                        })
                    }
                </div>
            </div>
            <div className="pago-efectivo__panel-numerico">
                <div className="panel-numerico">
                    {
                        TeclasPad.map((item, index) => {
                            if (item === "Delete") {
                                return <button key={index} className="panel-numerico__num-button panel-numerico__num-button--back"
                                    ref={(el) => (padRef.current[index] = el)}
                                    onClick={handlerBackSpace}>
                                    <i className="fas fa-delete-left"></i>
                                </button>
                            } else {
                                return <button key={index} className="panel-numerico__num-button"
                                    ref={(el) => (padRef.current[index] = el)}
                                    onClick={() => handlerNumericPad(item)}>{item}</button>
                            }
                        })
                    }
                </div>
            </div>
            <div className="pago-efectivo__footer">
                <div className="pago-efectivo__footer-button">
                    <ButtonText
                        texto="Cancelar movimiento"
                        size="large"
                    />
                </div>
                <div className="pago-efectivo__footer-button">
                    <Button
                        width="100%"
                        texto={`Pagar ${numberFormat(Total)}`}
                        size="large"
                        onClick={() => {
                            if (Total > Cantidad) {
                                ShowAlert({
                                    titulo: "La cantidad aun no cubre el total",
                                    icono: "warning",
                                    time: 4,
                                    mensaje: "Corrobora si la cantidad ingresada es igual o mayor que el total de la venta",
                                    // botones: [
                                    //     {
                                    //         texto: ""
                                    //     }
                                    // ]
                                })
                                return;
                            }
                            changeScreen("Terminado")
                        }}
                    />
                </div>
            </div>
        </div>
    </>
}