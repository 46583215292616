import ImpresionReceta from "../../components/Receta/ImpresionReceta/ImpresionReceta"
import { useObtenerRecetaQuery } from "../../services/receta"

export default function RecetaPrint({ receta }) {
    const { data: Receta } = useObtenerRecetaQuery({ receta: receta })

    return (
        <>{Receta ? <ImpresionReceta
            receta={Receta}
        >

        </ImpresionReceta> : undefined}</>

    )
}