import "./punto-venta.css";
import { createContext, useContext } from "react";

const PuntoVentaContext = createContext();

export const PuntoVentaProvider = ({ children, ShowAlert }) => {
    return (
        <PuntoVentaContext.Provider value={{ ShowAlert }}>
            <div className="punto-venta__box">
                {children}
            </div>
        </PuntoVentaContext.Provider>

    );
}

export const usePuntoVenta = () => {
    return useContext(PuntoVentaContext);
};

