
import "./desglose.css";
import { Input, ButtonIcon, Button, LazyImage, InputSearch } from "eureka-design";
import imagen from "../../../imgs/producto.png"
import { useState, useEffect } from "react";

export default function DesgloseDetalles({ productos = [], deleteConcepto = () => { }, changeScene = () => {

} }) {
    const [Productos, setProductos] = useState(productos);
    const [Total, setTotal] = useState(0);

    useEffect(() => {
        if (productos.length === 0) return;
        let total = 0;
        productos.forEach(producto => {
            total += producto.precio * producto.cantidad;
        });
        setTotal(total);
        setProductos(productos);
    }, [productos]);

    const ListaProductos = () => {
        if (Productos.length > 0) {
            return (<div className="detalles-desglose__conceptos">
                {
                    Productos.map((producto, i) => {
                        return <div key={i} className="detalles-desglose__concepto">
                            <div className="detalles-desglose__concepto-box">
                                <div className="detalles-desglose__concepto-imagen">
                                    <LazyImage src={imagen} />
                                </div>
                                <div className="detalles-desglose__concepto-info">
                                    <div className="detalles-desglose__concepto-detalle">
                                        <div className="detalles-desglose__concepto-titulo">
                                            <span>{producto.nombre}</span>
                                            <div className="detalles-desglose__concepto-close" onClick={() => {
                                                deleteConcepto(i);
                                            }}>
                                                <i className="fas fa-times"></i>
                                            </div>
                                        </div>
                                        <div className="detalles-desglose__concepto-precio">
                                            <span>${producto.precio}.00</span>
                                        </div>
                                    </div>

                                    <div className="detalles-desglose__concepto-total">
                                        <div className="detalles-desglose__concepto-cantidad">
                                            <div className="detalles-desglose__concepto-cantidad__menos">
                                                <i className="fas fa-minus"></i>
                                            </div>
                                            <div className="detalles-desglose__concepto-cantidad__conteo">
                                                {producto.cantidad}
                                            </div>
                                            <div className="detalles-desglose__concepto-cantidad__mas">
                                                <i className="fas fa-plus"></i>
                                            </div>
                                        </div>
                                        <div className="detalles-desglose__concepto-subtotal">
                                            <span>${producto.precio * producto.cantidad}.00</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>)
        } else {
            return <div className="detalles-desglose__no-content">
                <div className="detalles-desglose__no-content-titulo">
                    Sin productos seleccionados
                </div>
                <div className="detalles-desglose__no-content-texto">
                    Agrega productos a la venta
                </div>
            </div>
        }
    }

    return (
        <div className="detalles-desglose__card">
            <div className="detalles-desglose__header">
                <div className="detalles-desglose__header-info">
                    <div className="detalles-desglose__header-title">
                        <h2>Venta #15689</h2>
                    </div>
                    <div className="detalles-desglose__header-buttons">
                        <ButtonIcon icono="fa-trash" />
                    </div>
                </div>
                <div className="detalles-desglose__header-detalle">
                    <InputSearch
                        icono="fa-search"
                        placeholder="Buscar cliente por nombre"
                    />
                </div>
            </div>
            <div className="detalles-desglose__body">
                {ListaProductos()}
            </div>
            <div className="detalle-desglose__footer">
                <div className="detalle-desglose__footer-total">
                    <span>TOTAL:</span>
                    <font>${Total}.00</font>
                </div>
                <Button
                    width="100%"
                    texto="Realizar venta"
                    size="large"
                    onClick={() => { changeScene("Metodo") }}
                />
            </div>
        </div>
    )
}