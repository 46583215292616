import "./impresion-receta.css";
import firma from "../../../imgs/firma.png";
import { Button } from "eureka-design"
import React, { useRef, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import { formatFecha, numberFormat } from "../../../js/util";

const PrintableComponent = React.forwardRef((receta, ref) => {
    const Receta = useMemo(() => {
        if (receta) {

            let mi_receta = receta.receta
            let conceptos = mi_receta.eureka_st_transacciones_conceptos ?? [];
            let total = 0;
            conceptos = conceptos.map((concepto) => {
                let hora = concepto.eureka_st_transacciones_periodo?.hora.slice(0, 2);

                hora = hora.startsWith("0") ? hora.slice(1) : hora
                let precio = 0;
                let inventario = 0;
                let similares = [];
                concepto.eureka_st_producto?.eureka_st_productos_precios.forEach((p) => {
                    if (p.precio > precio) {
                        precio = p.precio
                    }
                    total += p.precio
                })
                concepto.eureka_st_producto?.eureka_st_productos_almacenes.forEach((a) => {
                    inventario += a.cantidad
                })
                concepto.eureka_st_producto?.eureka_productos_similares.forEach((s) => {
                    if (s.id === concepto.eureka_st_producto.id) return
                    similares.push({
                        id: s.id,
                        codigo: s.codigo,
                        nombre: s.codigo,
                        inventario: s.eureka_st_productos_almacenes.reduce((acc, a) => acc + a.cantidad, 0),
                        precio: s.eureka_st_productos_precios[0].precio,
                    })
                })
                return {
                    id: concepto.id,
                    costo: concepto.costo,
                    nombre: concepto.eureka_st_producto?.nombre,
                    codigo: concepto.eureka_st_producto?.codigo,
                    descripcion: concepto.eureka_st_producto?.descripcion,
                    categoria: concepto.eureka_st_producto?.eureka_st_nivele?.nombre,
                    cantidad: concepto.cantidad,
                    precio: precio,
                    inventario: inventario,
                    similares: similares,
                    total: concepto.total,
                    periodo: {
                        id: concepto.eureka_st_transacciones_periodo?.id,
                        cantidad: concepto.eureka_st_transacciones_periodo?.cantidad,
                        hora: hora,
                        nombre: concepto.eureka_st_transacciones_periodo?.nombre,
                        total: concepto.eureka_st_transacciones_periodo?.total,
                    }
                }
            })

            return {

                id: mi_receta.id,
                estatus: mi_receta.estatus,
                total: mi_receta.total,
                cliente: mi_receta.eureka_bs_cliente ?? {},
                vendedor: mi_receta.eureka_ac_vendedor ?? {},
                conceptos: conceptos ?? [],
                fecha: new Date(mi_receta.fecha),
                detalle: mi_receta.eureka_st_transacciones_detalles[0] ? mi_receta.eureka_st_transacciones_detalles[0].texto : "Sin diagnóstico",
            }
        } else return {}

    }, [receta])
    return (
        <section id="print" ref={ref}>
            <div className="receta-print__header">
                <div className="receta-print__header-logo">

                </div>
                <div className="receta-print__header-info">
                    <div className="receta-print__header-titulo">Dr. {Receta?.vendedor?.nombre}</div>
                    <div className="receta-print__header-subtitulo">MÉDICO GENERAL</div>
                    <div className="receta-print__header-detalle">
                        <span>D.G.P. |12134547</span>
                        <span>Cédula: 12345678</span>
                    </div>
                </div>
            </div>
            <div className="receta-print__body">
                <div className="receta-print__paciente">
                    <div className="receta-print__paciente-info">
                        <span className="receta-print__paciente-info__titulo">Paciente:</span>
                        <span className="receta-print__paciente-info__texto">{Receta?.cliente?.nombre}</span>
                    </div>
                    <div className="receta-print__paciente-info">
                        <span className="receta-print__paciente-info__titulo">Edad:</span>
                        <span className="receta-print__paciente-info__texto">Sin identificar</span>
                    </div>
                    <div className="receta-print__paciente-info">
                        <span className="receta-print__paciente-info__titulo">Fecha:</span>
                        <span className="receta-print__paciente-info__texto">{formatFecha(Receta?.fecha)}</span>
                    </div>
                </div>
                <div className="receta-print__diagnostico">
                    <div className="receta-print__diagnostico-titulo">
                        <span>Diagnóstico:</span>
                    </div>
                    <div className="receta-print__diagnostico-descripcion">
                        <span>{Receta.detalle}</span>
                    </div>
                </div>
                <div className="recetas-print__conceptos">
                    <div className="receta-print__conceptos-header">
                        <div className="recetas-print__conceptos-titulo">
                            <span>{Receta.conceptos.length} Medicamentos recetados</span>
                        </div>
                        <div className="recetas-print__conceptos-total">
                            {numberFormat(Receta.total)}
                        </div>
                    </div>
                    <div className="receta-print__conceptos-lista">
                        {
                            Receta.conceptos.map((concepto, index) => {
                                return (
                                    <div key={index} className="receta-print__conceptos-concepto">
                                        <span>{concepto.codigo}</span> - <span>{concepto.descripcion}</span> -
                                        <span>Tomar {concepto.periodo.total} cada {concepto.periodo.hora} horas durante {concepto.periodo.cantidad} {concepto.periodo.nombre}</span>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className="receta-print__footer">
                    <div className="receta-print__firma">
                        <img src={firma} alt="firma" />
                    </div>
                </div>
            </div>
        </section>
    );
});

const ImpresionReceta = ({ receta }) => {
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        contentRef: printRef, // Usa contentRef en lugar de content
    });


    return <>
        <div className="receta-print">
            <div className="receta-print__card">
                <PrintableComponent receta={receta} ref={printRef} />
                <div className="receta-print__botones">
                    <Button
                        texto="Imprimir"
                        onClick={handlePrint}
                    />
                </div>
            </div>
        </div>
    </>
}

export default ImpresionReceta