import "./metodo-pago.css"
import Efectivo from "./efectivo"
import { useState } from "react";

const pantalla = Object.freeze({
    Efectivo: "Efectivo",
    Tarjeta: "Tarjeta",
    Espera: "Espera",

});

const MetodoPago = ({ changeScene = () => {

} }) => {
    const [Pantalla, setPantalla] = useState(pantalla.Efectivo);

    const handlerPantalla = (screen) => {
        let result = pantalla[screen] ?? null
        if (result) {
            setPantalla(pantalla[screen])
        }
    }

    return <>
        <div className="metodo-pago">
            <div className="metodo-pago__opciones">
                <div className={`metodo-pago__metodo ${Pantalla == pantalla.Tarjeta ? "metodo-pago__metodo--active" : ""}`} onClick={e => {
                    setPantalla(pantalla.Tarjeta)
                }}>
                    <div className="metodo-pago__metodo-box">
                        <div className="metodo-pago__metodo-icono">
                            <i className="fas fa-credit-card"></i>
                        </div>
                        <div className="metodo-pago__metodo-text">
                            Tarjeta
                        </div>
                    </div>
                </div>
                <div className={`metodo-pago__metodo ${Pantalla == pantalla.Efectivo ? "metodo-pago__metodo--active" : ""}`} onClick={e => {
                    setPantalla(pantalla.Efectivo)
                }}>
                    <div className="metodo-pago__metodo-box">
                        <div className="metodo-pago__metodo-icono">
                            <i className="fas fa-dollar-sign"></i>
                        </div>
                        <div className="metodo-pago__metodo-text">
                            Efectivo
                        </div>
                    </div>
                </div>
            </div>
            <div className="metodo-pago__content">
                {Pantalla == pantalla.Efectivo ? <Efectivo
                    total={560.30}
                    changeScreen={changeScene}
                /> : undefined}
            </div>
        </div>
    </>
}

export default MetodoPago