import { Tabla, buildData, Miniatura, ChipEstatus } from 'eureka-design'
import { useEffect, useState } from 'react'
import { NOM_FORM_DOCTOR } from './form-doctor'
import { useCrearDoctorMutation, useObtenerDoctoresQuery } from '../../services/doctor'
import { obtenerLogo } from '../../js/util'

const ListaDoctores = ({ Push, ShowAlert }) => {
    const { data, isLoading, refetch } = useObtenerDoctoresQuery()

    const [crearDoctor] = useCrearDoctorMutation()

    const [totalActivos, fijaTotalActivos] = useState(0)
    const [totalArchivados, fijaTotalArchivados] = useState(0)

    useEffect(() => {
        const calcularTotal = (pacientes = [], estatus = 'activo') => {
            return pacientes.filter((paciente) => paciente?.estatus === estatus).length
        }

        fijaTotalActivos(calcularTotal(data, 'activo'))
        fijaTotalArchivados(calcularTotal(data, 'inactivo'))
    }, [data])

    const handlerRegistrar = () => {
        Push('form_doctor', {
            [NOM_FORM_DOCTOR]: {},
            titulo: 'Agregar nuevo doctor'
        })
    }

    const handlerEditar = (item) => {
        Push('form_doctor', {
            [NOM_FORM_DOCTOR]: { ...item.item },
            titulo: `Dr. ${item.item.nombre}`
        })
    }

    const handlerCambiarEstatus = (item) => {
        let doctor = item.item
        let nuevoEstatus = ''
        let mensaje = ''

        if(doctor.estatus === 'activo') {
            nuevoEstatus = 'inactivo'
            mensaje = 'El doctor a cambiado a inactivo'
        }
        else {
            nuevoEstatus = 'activo'
            mensaje = 'El doctor a cambiado a activo'
        }
        crearDoctor({
            id: doctor.id,
            body: {
                estatus: nuevoEstatus
            }
        })
        .unwrap()
        .then(() => {
            refetch()
            ShowAlert({
                icono: 'success',
                titulo: 'Cambio de Estatus Correcto',
                mensaje: mensaje,
                time: 3,
            })
        })
    }

    const handlerArchivar = (item) => {
        let doctor = item.item

        crearDoctor({
            id: doctor.id,
            body: { estatus: 'bloqueado' }
        })
        .unwrap()
        .then(() => {
            refetch()
            ShowAlert({
                icono: 'success',
                titulo: 'Doctor Archivado',
                mensaje: 'El doctor se a archivado correctamente',
                time: 3,
            })
        })
    }

    const handlerVerDetalles = (item) => {
        console.log(item.item)
        Push('info_doctor', {
            doctor: item.item,
        })
    }

    if(isLoading) return
    else {
        return (
            <>            
                <Tabla 
                    checkbox={false}
                    rowSize="medium"
                    noData="No hay doctores registrados"
                    busqueda={{ placeholder: "Buscar...", keys: ["nombre"] }}
                    keys={[
                        {
                            icono: 'fa-users',
                            text: "Todos",
                            detalle: totalActivos + totalArchivados,
                            filtro: () => true
                        },
                        {
                            icono: 'fa-circle-check',
                            text: "Activos",
                            detalle: totalActivos,
                            filtro: item => item.item.estatus === "activo"
                        },
                        {
                            icono: 'fa-triangle-exclamation',
                            text: "Archivados",
                            detalle: totalArchivados,
                            filtro: item => item.item.estatus === "inactivo"
                        },
                    ]}
                    headers={[
                        { key: 'nombre',   text: 'Nombre',             type: 'text' },
                        { key: 'correo',   text: 'Correo eléctronico', type: 'text' },
                        { key: 'telefono', text: 'Teléfono',           type: 'text' },
                        { key: 'estatus',  text: 'Estado',             type: 'text' },
                    ]}
                    data={buildData(data, [
                        { 
                            key: 'nombre',       
                            type: 'text',
                            text: (item) => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                        <Miniatura src={obtenerLogo(item.eureka_cr_multimedia, 'foto')} avatar={true} />
                                        <span>{item.nombre}</span>
                                    </div>
                                )
                            }
                        },
                        { key: 'correo',   type: 'text' },
                        { key: 'telefono', type: 'text' },
                        { 
                            key: 'estatus',  
                            type: 'text',
                            text: (item) => {
                                const paciente = item
                                let texto, tipo
                                if(paciente.estatus === 'activo') {
                                    texto = 'Activo'
                                    tipo = 'Aceptado'
                                }
                                else {
                                    texto = 'Suspendido'
                                    tipo = 'Error' 
                                }
                                return <ChipEstatus texto={texto} tipo={tipo}/>
                            }
                        },
                    ])}
                    botonesHead={[
                        { icono: 'fa-plus', tooltip: 'Agregar', onClick: handlerRegistrar },
                    ]}
                    botonesRow={[
                        { icono: 'fa-pen',       tooltip: 'Editar',   onClick: handlerEditar },
                        { icono: 'fa-rotate', tooltip: 'Cambiar Estatus', onClick: handlerCambiarEstatus },
                        { icono: 'fa-trash-can', tooltip: 'Archivar', onClick: handlerArchivar },
                    ]}
                    onClickRow={handlerVerDetalles}
                />
            </>
        )
    }
}

export default ListaDoctores